.vh-100 {
  height: 100vh;
}
.h-50p {
  height: 50px;
}
.h-70p {
  height: 70%;
}
.mnh-400 {
  min-height: 400px !important;
}
.mxh-500p {
  max-height: 500px;
}
.mxh-400p {
  max-height: 400px;
}
.h-150p {
  height: 150px !important;
}
.h-300p {
  height: 300px !important;
}
.h-400p {
  height: 400px !important;
}
.h-200p {
  height: 200px !important;
}
.h-500p {
  height: 500px !important;
}

.w-10p {
  width: 10% !important;
}

.w-20p {
  width: 20% !important;
}
.w-30p {
  width: 30% !important;
}
.w-35p {
  width: 35% !important;
}
.w-40p {
  width: 40% !important;
}
.w-60p {
  width: 60% !important;
}
.w-65p {
  width: 65% !important;
}
.w-70p {
  width: 70% !important;
}
.w-75p {
  width: 75% !important;
}
.w-80p {
  width: 80% !important;
}
.w-90p {
  width: 90% !important;
}
.w-160p {
  width: 160px !important;
}

/* //height */

.h-10p {
  height: 10% !important;
}
.h-15p {
  height: 15% !important;
}
.h-20p {
  height: 20% !important;
}
.h-60p {
  height: 60% !important;
}
.h-80p {
  height: 80% !important;
}
.h-85p {
  height: 85% !important;
}
.h-100p {
  height: 100% !important;
}
.h-55px {
  height: 55px !important;
}
