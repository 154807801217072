.image-container {
  position: relative;
  
}

.vertical img{
  width: 50%;
}
.static {
  width: 30%;
  top: 20%;
  left: 55%;
  position: absolute;
}

.static img {
  width: 80%;
}

.horizontal {

  display: flex;
  width: 90%;
  justify-content: center;
  top: 65%;
  left: 10%;
  position: absolute;
}

.horizontal img {
  width: 90%;
}

.black-cap{
  top: 20%;
  left: -10%;
  position: absolute;
}

.black-cap img{
  width: 70%;
  opacity: 90%;
}

.blue-cap{
  top:105%;
  left: 35%;
  position: absolute;
}

.blue-cap img{
  width: 60%;
  opacity: 90%; 
}

.vertical img{
  width: 50%;
}
.static {
  width: 30%;
  top: 20%;
  left: 55%;
  position: absolute;
}

.static img {
  width: 80%;
}

.horizontal {

  display: flex;
  width: 90%;
  justify-content: center;
  top: 65%;
  left: 10%;
  position: absolute;
}

.horizontal img {
  width: 90%;
}

.black-cap{
  top: 20%;
  left: -10%;
  position: absolute;
}

.black-cap img{
  width: 70%;
  opacity: 90%;
}

.blue-cap{
  top:105%;
  left: 35%;
  position: absolute;
}

.blue-cap img{
  width: 60%;
  opacity: 90%; 
}

.black-image {
  position: absolute;
  animation: move45deg-up 7s linear infinite;
}

.blue-cap-small{
  top:65%;
  left:50%;
  position: absolute;
  opacity: 90%;
}

.blue-cap-small img{
  width: 40%;
}

@keyframes move45deg-up {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-10%, -15%);
  }
  50% {
    transform: translate(-20%, -30%); 
  }
  75% {
    transform: translate(-10%, -15%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.blue-image {
  position: absolute;
  animation: move45deg-down 7s linear infinite;
}

@keyframes move45deg-down {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10%, 15%);
  }
  50% {
    transform: translate(20%, 30%);
  }
  75% {
    transform: translate(10%, 15%);
  }
  100% {
    transform: translate(0, 0);
  }
}
.dots-image {
  position: absolute;
  animation: move45deg 7s linear infinite;
}

@keyframes move45deg {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10%, -15%);
  }
  50% {
    transform: translate(20%, -30%);
  }
  75% {
    transform: translate(10%, -15%);
  }
  100% {
    transform: translate(0, 0);
  }
}


.grey-capsule {
  position: absolute;
  animation: move90deg-horizontal 7s linear infinite;
}

@keyframes move90deg-horizontal {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(15%, 0);
  }
  50% {
    transform: translate(30%, 0);
  }
  75% {
    transform: translate(15%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.blue-capsule {
  position: absolute;
  animation: move90deg-vertical 7s linear infinite;
}

@keyframes move90deg-vertical {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -15%);
  }
  50% {
    transform: translate(0, -30%);
  }
  75% {
    transform: translate(0, -15%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.triangle-animation {
  position: absolute;
  animation: moveTriangle 7s linear infinite;
}

@keyframes moveTriangle {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(10%, -10%);
  }
  66% {
    transform: translate(-10%, -10%);
  }
  100% {
    transform: translate(0, 0);
  }
}


.video-container {
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

