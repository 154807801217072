//white color
$white_1: #ffffff;
$white_2: #D3D2AE;
$white_3: #d3d2ae;

//blue color
$blue_1:  #24abe3;
$blue_2:  #24ABE3;
$blue_3:  #081d44;
$blue_4:  #72D0F8;
$blue_5:  #8dabe142;


//orange color
$orange_1:#FA921F;

//black color
$black_1: #000;

//grey color
$grey_1: #808080;
$grey_2: #81808C;
$grey_3: #8DABE142;
