@import "./_variable.scss";

.options-container {
  display: flex;
  flex-wrap: wrap;
}
.hvhCVC {
  color: $blue_2 !important;
  background-color: $white_1 !important;
  border: 1px solid $blue_2 !important;
}

.hvhCVC:hover {
  color: $white_1 !important;
  background-color: $blue_2 !important;
  opacity: 1 !important;
}
.chatbot-container {
  position: fixed;
  bottom: 5px;
  right: 8px;
  z-index: 1000;
}

.react-chatbot-kit-chat-header {
  background-color: $blue_2 !important;
  color: $white_1 !important;
}