@import "./_variable.scss";

.input-fileds {
  background-color: $blue_5 !important;
  height: 4.7vh;
}
.input-fileds:focus {
  outline: none !important;
  border: 0px;
}

.input-fileds::placeholder {
  font-size: 12px;
}
.cv::placeholder{
   text-align: center;
   font-size: 14px;
   font-weight: 700;
}
.cv{
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 1rem; 
    background-color: $white_1; 
    border: 0;
    box-sizing: border-box; 
    font-size: 1rem; 
    color: $black_1; 
    text-align: center; 
    line-height: 1.5; 
    cursor: pointer; 
    outline: none;
}

.textarea {
  background-color: $blue_5 !important;
  width: 20.83vw !important;
}
.textarea:focus {
  outline: none !important;
  border: 0px;
}

textarea::placeholder {
  font-size: 12px;
}

/* ********* event slider navbars******** */
.slick-dots li button:before {
font-size: 10px !important;
  color: $white_1 !important;
  opacity: 0.75;
}
.slick-dots li {
  margin: 0 0px !important; 
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: $white_1 !important; 
}
/* ******************** */

