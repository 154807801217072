@import "./_variable.scss";

.home-blog {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 5%;
  padding-bottom: 5%;
  overflow: hidden;
}

.home-blogs-articles-RoundedRectangleBox {
  z-index: -2 !important;
  top: -30%;
  height: 70%;
  width: 18%;
}

.homepage-carousal {
  position: absolute !important;
 

  @media (max-width: 480px) {
    bottom: 11% !important;
    left: 10% !important;
    justify-content: center !important;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    bottom: 6% !important;
    left: 13% !important;
    justify-content: start !important;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    bottom: 9% !important;
    left: 19.2% !important;
    justify-content: start !important;
  }

  @media (min-width: 1025px) {
    bottom: 10% !important;
    left: 19% !important;
    justify-content: start !important;
  }
}
