@import "./_variable.scss";

.txt-description {
  color: $grey_2;
  
}

.blue-background {
  background-color: $grey_3;
}

.chip-title-blue {
  color: $blue_2;
}

.lightblue-background {
  background-color: $blue_2;
}


.color-orange {
  color: $orange_1 !important;
}

.dark-blue {
  color: $blue_3 !important;
}

.Shadow-effects {
  box-shadow: 0px 10px 60px rgba(25, 24, 37, 0.08);
}

.btn-shadow :hover {
  background-Color: transparent;
  box-shadow: none;
}

.description :hover {
  color:$white_1
}
.btn-hover-background:hover,
.btn-hover-background:focus,
.btn-hover-background:active {
  background-color: transparent !important;
}

/* ************gradient-color*********** */

.gradient-border {
  border-top: 2px solid;
  border-image-source: linear-gradient(90deg, $orange_1 0%, $white_2 50%, $blue_2 100%);
  border-image-slice: 1;
}

.divider-color {
  background-image: linear-gradient(to right,
      $orange_1,
      $white_3,
      $blue_1);
}

/* * footer-1st-part* */
.footer-first-part-body {
  padding: 3%;
  border-radius: 2px;
  background-image: linear-gradient(to right,
      $orange_1,
      $white_3,
      $blue_1);
}

/* * footer-1st-part contact page* */
.contact-footer-part {
  margin-top: 1%;
  padding: 1%;
  border-radius: 2px;
  background-image: linear-gradient(to right,
      $orange_1,
      $white_3,
      $blue_1);
}

