* {
  font-family: "Play", sans-serif !important;
}

::-webkit-scrollbar {
  display: none;
}

.content-links{
  color: #24ABE3;
  text-decoration: none;
}


