/* Font Size */
.fs-8 {
  font-size: 0.67rem !important;
}

.fs-9-point-5 {
  font-size: 0.79rem !important;
}

.fs-10 {
  font-size: 0.67rem !important;
}

.fs-12 {
  font-size: 0.79rem !important
}

.fs-14 {
  font-size: 0.83rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.17rem !important
}

.fs-20 {
  font-size: 1.33rem !important;
}

.fs-22 {
  font-size: 1.50rem !important;
}

.fs-24 {
  font-size: 1.67rem !important;
}

.fs-26 {
  font-size: 1.83rem !important;
}

.fs-28 {
  font-size: 2.00rem !important;
}

.fs-30 {
  font-size: 2.17rem !important;
}

.fs-35 {
  font-size: 2.33rem !important;
}

.fs-40 {
  font-size: 2.50rem !important;
}

.fs-45 {
  font-size: 2.92rem !important;
}

.fs-50 {
  font-size: 3.33rem !important;
}

.fs-55 {
  font-size: 3.75rem !important;
}

.fs-60 {
  font-size: 4.17rem !important;
}

.fs-68 {
  font-size: 4.58rem !important; 
}

.fs-75 {
  font-size: 5.00rem !important;
}

/* Font Weight */
.fw-900 {
  font-weight: 900 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}
