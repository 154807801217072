@import "./_variable.scss";

.cursor-pointer {
  cursor: pointer;
}
/* ********** heading color ***********/
.heading:hover .heading-color {
  color: $blue_3 !important;
  transition: color 0.3s ease;
}


.chip-component:hover {
  background-color: $blue_4 !important;
  color: $white_1 !important;
}

.content-chip1:hover {
  background-color: $blue_5 !important;
}

.content-chip2:hover {
  background-color: $white_1 !important;
}

.img-container {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.img-container img {
  display: block;
  border-radius: 50%;
}

.img-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $grey_1;
  border-radius: 50%;
  pointer-events: none;
}

/* ************************************ */

.sirvice-card:hover .card-texts {
  color: $white_1;
  background-color: $blue_1;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.sirvice-card:hover .card-text-color {
  color: $white_1;
  transition: color 0.3s ease;
}

.hover-bg-color:hover {
  color: $white_1 !important;
  background-color: $blue_1;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.hover-bg-color:hover .count,
.hover-bg-color:hover .title {
  color: $white_1 !important;
}

.sirvice-card:hover .card-bgcolor {
  background-color: $blue_1;
  transition: background-color 0.3s ease;
}

.vision-card:hover {
  background-color: $blue_1;
  transition: background-color 1s ease;
}
.vision-card:hover .vision-card-text {
  color:$white_1 !important;
}

.sirvice-card:hover .chip-component {
  background-color: $black_1 !important;
  color:$white_1 !important;
}
.sirvice-card:hover .card-image {
  filter: grayscale(65%);
}
.inudtries-card:hover {
  color: $white_1;
  background-color: $blue_1;
}
.inudtries-card:hover .inudtries-card-text {
  color: $white_1;
}

.inudtries-card:hover .inudtries-card-link-text {
  color: $black_1;
}

.inudtries-card:hover .svg-icon svg {
  fill: $white_1 !important;
}

.inudtries-card:hover .inudtries-card-footer-text {
  color: $black_1;
}
.inudtries-card:hover .inudtries-card-iconBgColor {
  background-color: $black_1;
}

.Semiconductor-card:hover .Semiconductor-card-footer-text {
  color: $white_1;
  background-color: $blue_1;
}

.navbar-links.active {
  border-bottom: 2px solid $blue_2 !important;
}
.navbar-links:hover {
  border-bottom: 2px solid $blue_1 !important;
}

.image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, transparent 50%, rgba(0, 0, 0, 0.3) 50%);
  background-size: 90% 200%;
  background-position: 0 100%;
  opacity: 0;
  transition: background-position 1s, opacity 1s;
}

.image-container:hover .overlay {
  background-position: 0 0 !important;
  opacity: 1;
}

.image-container:hover .container-text {
  color: $blue_1 !important;
}

/* home-page blogs card */

.card-container:hover .card-heading {
  color: $blue_1 !important;
}

.card-container:hover .card-border {
  border-bottom: 2px solid $blue_1 !important;
}

/* technology-page */
.technology-engg-services {
  background: linear-gradient(to top, $white_1 50%, $blue_1 50%);
  background-size: 100% 200%;
  background-position: 0 100%;
  transition: background-position 1s;
  color: $black_1 !important;
}

.technology-engg-services:hover {
  background-position: 0 0;
  color:$white_1 !important;
}

.decrease-scale {
  transform: scale(1);
  transition: transform 0.3s ease;
}

.decrease-scale:hover {
  transform: scale(0.97);
}

.increase-scale {
  transform: scale(1);
  transition: transform 0.3s ease;
}

.decrease-scale:hover .increase-scale {
  transform: scale(1.05);
}

.technology-engg-services:hover .txt-description {
  color:$white_1 !important;
}

/* Right-side */
.black-Capsule-Img {
  position: relative;
  animation: myfirst linear 30s infinite;
}

@keyframes myfirst {
  0% {
    right: 0px;
    top: 0px;
  }
  25% {
    left: 1%;
    right: 1%;
  }
  100% {
    right: 0px;
    top: 0px;
  }
}

.blue-Capsule-Img {
  position: relative;
  animation: myfirst linear 2s infinite;
}

@keyframes myfirst {
  0% {
    right: 0px;
    top: 0px;
  }
  25% {
    left: 2px;
    top: 2px;
  }
  25% {
    bottom: 2px;
    right: 2px;
  }
  100% {
    right: 0px;
    top: 0px;
  }
}

/* left-side */
.slick-animate {
  transition: opacity 0.48s ease-in-out;
}

.black-Capsule-Img-left {
  top: -10%;
  right: -5% !important;
  position: relative;
  animation: myfirst linear 3s infinite;
}

@keyframes myfirst {
  0% {
    right: 0px;
    top: 0px;
  }
  25% {
    top: -10%;
    right: 0px;
    bottom: 0px;
  }

  100% {
    right: 0px;
    top: 0px;
  }
}

/* Right-side */
.reuseable-black-Capsule-Img {
  position: relative;
  animation: myfirst linear 15s infinite;
}

@keyframes myfirst {
  0% {
    right: 1%;
    top: 1%;
  }
  50% {
    top: 0.1% !important;
    bottom: 0%;
  }
  100% {
    right: 1%;
    top: 1%;
  }
}

.reuseable-blue-Capsule-Img {
  position: relative;
  animation: myfirst linear 15s infinite;
}

@keyframes myfirst {
  0% {
    right: 1%;
    top: 1%;
  }
  25% {
    left: 0.5%;
    top: 0.5%;
  }
  25% {
    bottom: 0.5%;
    right: 0.5%;
  }
  100% {
    right: 1%;
    top: 1%;
  }
}

/* left-side */

.reuseable-black-Capsule-Img-left {
  position: relative;
  animation: myfirst linear 15s infinite;
}

@keyframes myfirst {
  0% {
    right: 0px;
    top: 0px;
  }
  25% {
    top: -10%;
    right: 0px;
    bottom: 0px;
  }

  100% {
    right: 0px;
    top: 0px;
  }
}

.right-side-Capsule {
  position: absolute;
  animation: move45deg 7s linear infinite;
}

@keyframes move45deg {
  0% {
    transform: translate(0, 0);
    transform: rotate(-45deg);
  }
  25% {
    transform: translate(-10%, -10%);
    transform: rotate(-45deg);
  }
  50% {
    transform: translate(-20%, -20%);
    transform: rotate(-45deg);
  }
  75% {
    transform: translate(-10%, -10%);
    transform: rotate(-45deg);
  }
  100% {
    transform: translate(0, 0);
    transform: rotate(-45deg);
  }
}

.home-blogs-animation{
  transform: rotate(45deg);
}

.loadContainer {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  z-index: 1900 !important;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}

.loader,
.loader:before,
.loader:after {
  background: $blue_1;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: $blue_1;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 6px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
