/* Media query for small devices (tablets) */
@media only screen and (max-width: 329px) {
  
  .case-study-description-height {
    height: 25vh !important;
  }
  .blue-cap img {
    width: 30%;
    opacity: 90%;
  }
  .black-cap img {
    width: 30%;
    opacity: 90%;
  }
  .vertical img {
    width: 50%;
  }

  .horizontal img {
    width: 90%;
  }
  .horizontal {
    display: flex;
    width: 90%;
    justify-content: center;
    top: 75%;
    left: 20%;
    position: absolute;
  }

  .static {
    width: 30%;
    top: 20%;
    left: 55%;
    position: absolute;
  }

  .static img {
    width: 80%;
  }

  .black-cap {
    top: 20%;
    left: -10%;
    position: absolute;
  }

  .blue-cap {
    top: 105%;
    left: 35%;
    position: absolute;
  }
  .blue-cap-small {
    top: 65%;
    left: 50%;
    position: absolute;
    opacity: 90%;
  }

  .blue-cap-small img {
    width: 40%;
  }
  .learning-Services-content {
    height: 40% !important;
  }
  .navbar-company-logo {
    width: 200%;
  }
  .homepage-carousel-dots{
    width: 20% !important;
  }
}

@media only screen and (min-width: 330px) and (max-width: 599px) {
  .products-image-box {
    width: 50% !important;
  }
  .navbar-icons {
    width: 90% !important;
    margin-left: 10px !important;
  }
  .navbar-company-logo {
    width: 200%;
  }
  .footer-icons {
    width: 60%;
  }
  .image-section-box {
    height: 25% !important;
  }
  .aboutus-image-box {
    height: 70vw;
    width: 70vw;
  }
 
  .youtube-video-height {
    height: 95%;
  }
  .learning-Services-content {
    height: 40% !important;
  }
  .homepage-carousel-dots{
    width: 20% !important;
  }
}



@media only screen and (min-width: 600px) and (max-width: 899px) {
  .products-image-box {
    width: 45% !important;
  }
  .navbar-icons {
    width: 90% !important;
    margin-left: 10px !important;
  }
  .navbar-company-logo {
    width: 150%;
  }
  .footer-icons {
    width: 100%;
  }

  .aboutus-image-box {
    height: 50vw;
    width: 50vw;
  }

  .youtube-video-height {
    height: 85%;
  }
  .learning-Services-content {
    height: 40% !important;
  }
  .homepage-carousel-dots{
    width: 20% !important;
  }
}

/* Media query for medium devices (desktops) */
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .products-image-box {
    width: 50% !important;
  }
  .navbar-icons {
    width: 80% !important;
  }
  .navbar-company-logo {
    width: 100%;
    margin: 3% !important;
  }
  .case-description-height {
    height: 90% !important;
  }
  .footer-icons {
    width: 120%;
  }
  .image-section-box {
    height: 100% !important;
  }
  .youtube-video-height {
    height: 80%;
  }
  .homepage-carousel-dots{
    width: 25.5% !important;
  }
}

/* Media query for large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  .products-image-box {
    width: 50% !important;
  }
  .navbar-icons {
    width: 90% !important;
  }
  .navbar-company-logo {
    width: 80%;
    margin: 2% !important;
  }
  .footer-icons {
    width: 100%;
  }
  .image-section-box {
    height: 100% !important;
  }
  .youtube-video-height {
    height: 85%;
  }
  .homepage-carousel-dots{
    width: 25% !important;
  }
}

/* ********** core-values our-company-page *********** */

@media only screen and (min-width: 900px) {
  .core-values-content {
    height: 110% !important;
  }
}

@media only screen and (min-width: 950px) {
  .core-values-content {
    height: 90% !important;
  }
}

@media only screen and (min-width: 1000px) {
  .core-values-content {
    height: 80% !important;
  }
}

@media only screen and (min-width: 1100px) {
  .core-values-content {
    height: 73% !important;
  }
}
@media only screen and (min-width: 1150px) {
  .core-values-content {
    height: 70% !important;
  }
}
@media only screen and (min-width: 1200px) {
  .core-values-content {
    height: 62% !important;
  }
}

/* ********** testimonials-card home-page *********** */

@media only screen and (min-width: 900px) {
  .testimonials-content {
    height: 50vh !important;
  }
}

@media only screen and (min-width: 950px) {
  .testimonials-content {
    height: 48vh !important;
  }
}

@media only screen and (min-width: 1000px) {
  .testimonials-content {
    height: 45vh !important;
  }
}

@media only screen and (min-width: 1100px) {
  .testimonials-content {
    height: 42vh !important;
  }
}
@media only screen and (min-width: 1150px) {
  .testimonials-content {
    height: 40vh !important;
  }
}
@media only screen and (min-width: 1200px) {
  .testimonials-content {
    height: 42vh !important;
  }
}
@media only screen and (min-width: 1300px) {
  .testimonials-content {
    height: 35vh !important;
  }
}

/* ********** Learning Services Service-page *********** */

@media only screen and (min-width: 900px) {
  .learning-Services-content {
    height: 85% !important;
  }
}

@media only screen and (min-width: 950px) {
  .learning-Services-content {
    height: 78% !important;
  }
}

@media only screen and (min-width: 1000px) {
  .learning-Services-content {
    height: 70% !important;
  }
}

@media only screen and (min-width: 1100px) {
  .learning-Services-content {
    height: 60% !important;
  }
}
@media only screen and (min-width: 1150px) {
  .learning-Services-content {
    height: 50% !important;
  }
}
@media only screen and (min-width: 1200px) {
  .learning-Services-content {
    height: 40% !important;
  }
}

/* ********** Services We Offer home-page *********** */

@media only screen and (min-width: 600px) {
  .Services-Offer-content {
    height: 50% !important;
  }
  .Services-title-text {
    font-size: 90% !important;
  }
}
@media only screen and (min-width: 650px) {
  .Services-Offer-content {
    height: 45% !important;
  }
  .Services-title-text {
    font-size: 95% !important;
  }
}
@media only screen and (min-width: 700px) {
  .Services-Offer-content {
    height: 40% !important;
  }
  .Services-title-text {
    font-size: 100% !important;
  }
}
@media only screen and (min-width: 750px) {
  .Services-Offer-content {
    height: 37% !important;
  }
  .Services-title-text {
    font-size: 100% !important;
  }
}
@media only screen and (min-width: 800px) {
  .Services-Offer-content {
    height: 35% !important;
  }
  .Services-title-text {
    font-size: 100% !important;
  }
}

@media only screen and (min-width: 900px) {
  .Services-Offer-content {
    height: 38% !important;
  }
  .Services-title-text {
    font-size: 105% !important;
  }
}
@media only screen and (min-width: 1000px) {
  .Services-Offer-content {
    height: 35% !important;
  }
  .Services-title-text {
    font-size: 110% !important;
  }
}
@media only screen and (min-width: 1100px) {
  .Services-Offer-content {
    height: 35% !important;
  }
  .Services-title-text {
    font-size: 120% !important;
  }
}
@media only screen and (min-width: 1200px) {
  .Services-title-text {
    font-size: 140% !important;
  }
}

/* ********** Managed Services and Consulting Services  Services-page *********** */

@media only screen and (min-width: 900px) {
  .Consulting-card-height {
    height: 135% !important;
    width: 110% !important;
  }
  .Consulting-text-size{
    font-size: 95% !important;
  }

  .Managed-card-height {
    height: 125% !important;
    width: 100% !important;
  }
  .Managed-text-size{
    font-size: 85% !important;
  }
  .ourProducts-card-height {
    height: 125% !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .Consulting-card-height {
    height: 115% !important;
    width: 110% !important;
  }
  .Consulting-text-size{
    font-size: 120% !important;
  }
  .Managed-card-height {
    height: 105% !important;
    width: 100% !important;
  }
  .Managed-text-size{
    font-size: 90% !important;
  }
  .ourProducts-card-height {
    height: 105% !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 1300px){
  .Managed-text-size{
    font-size: 100% !important;
  }
  .Consulting-text-size{
    font-size: 140% !important;
  }
}

@media only screen and (min-width: 900px) {
  .Consulting-card-height {
    padding-bottom: 8% !important;
  }

}
@media only screen and (min-width: 1200px) {
  .Consulting-card-height {
    padding-bottom: 6% !important;
  }
}

@media only screen and (min-width: 300px) {
  .card-text-align {
    margin-bottom: 10% !important;
  }
}


